import { render, staticRenderFns } from "./Accept.vue?vue&type=template&id=20e6d8b6&scoped=true&"
import script from "./Accept.vue?vue&type=script&lang=js&"
export * from "./Accept.vue?vue&type=script&lang=js&"
import style0 from "./Accept.vue?vue&type=style&index=0&id=20e6d8b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e6d8b6",
  null
  
)

export default component.exports