<template>
  <div>
    <v-card-text class="px-0 py-0">
      <v-stepper elevation="0" v-model="step">
        <v-stepper-items>
          <v-stepper-content :complete="step > 1" step="1" class="px-0 py-0">
            <v-form
              v-model="valid"
              ref="form-1"
              @submit.prevent="handlerNextStep()"
            >
              <v-col cols="12" class="px-0 py-0 pb-4">
                <p class="black--text" v-if="partner">
                  {{ partner.name }}, date of birth
                  {{ showDate(partner.dateOfBirth) }} has invited you to connect
                  as their {{ partner.relationship }}. By accepting this
                  invitation, you will be able to see their calendar, messages,
                  labs, documents, appointments and payments.
                </p>
              </v-col>

              <v-col cols="12" class="px-0 py-0 pb-4">
                <p class="black--text">
                  By linking partner accounts in Prelude Connect you authorize
                  your designated partner to receive any and all protected
                  health information maintained by your clinic (whether in
                  Prelude Connect or otherwise), including but not limited to
                  History/Physical Exams, Past/Present Medications, Lab Results,
                  Physician’s Orders, Patient Allergies, Operation Reports,
                  Consultation Reports, Progress Notes, Discharge Summary(ies),
                  Diagnostic Test Reports, EKG/Cardiology Reports, Pathology
                  Reports, Billing Information, Radiology Reports & Images.
                  Further, I specifically consent to the release of the
                  following information:
                </p>
              </v-col>

              <v-checkbox
                class="grey--text align"
                label="Mental Health Records (including psychotherapy
              notes)."
                v-model="check_1"
                @change="checkbox('check_1')"
              />
              <v-checkbox
                class="grey--text align"
                label="Genetic Information (including Genetic Test
              Results)."
                v-model="check_2"
                @change="checkbox('check_2')"
              />
              <v-checkbox
                class="grey--text align"
                label="Drug, Alcohol, or Substance Abuse Records."
                v-model="check_3"
                @change="checkbox('check_3')"
              />
              <v-checkbox
                class="grey--text align"
                label="HIV/AIDS Test Results/Treatment."
                v-model="check_4"
                @change="checkbox('check_4')"
              />

              <v-btn
                class="mt-8"
                color="primary"
                :disabled="
                  loading ||
                  !data.check_1 ||
                  !data.check_2 ||
                  !data.check_3 ||
                  !data.check_4
                "
                rounded
                block
                large
                type="submit"
              >
                Agree & Accept Invitation
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form
              v-model="valid"
              ref="form-2"
              @submit.prevent="handlerNextStep()"
            >
              <v-card
                class="mx-auto d-flex items-center justify-center"
                dark
                color="#f5f5f5"
                elevation="0"
                height="400px"
              >
                <v-card-title
                  class="text-subtitle-2 black--text text-center"
                  v-if="consent"
                >
                  {{ consent.text }}
                </v-card-title>
              </v-card>
              <v-btn
                class="mt-8"
                color="primary"
                rounded
                block
                large
                type="submit"
              >
                Agree & Send Invitation
              </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-spacer />
  </div>
</template>

<script>
import { api, auth, partner } from '../../sharedPlugin';
export default {
  data() {
    return {
      valid: false,
      step: 1,
      loading: false,
      consent: null,
      partner: null,
      data: {
        allowPartnerSeeInformation: false,
        check_1: false,
        check_2: false,
        check_3: false,
        check_4: false
      },
      rules: {
        allowPartnerSeeInformation: [(v) => !!v || '']
      },
      check_1: false,
      check_2: false,
      check_3: false,
      check_4: false
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner'])
  },
  watch: {},
  methods: {
    async loadConsents() {
      this.loading = true;
      await api.PartnerConnect.getConsents()
        .then((res) => {
          if (res && res.length > 0) {
            this.consent = res[0];
            if (this.consent && this.consent.text) {
              this.consent.text = this.consent.text.replace(/<[^>]+>/g, '');
            }
          }
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            message: 'Consent unavailable',
            color: 'red'
          });
        })
        .finally(() => (this.loading = false));
    },
    handlerNextStep() {
      this.valid = false;
      let v = this.$refs[`form-${this.step}`].validate();
      if (v) {
        this.valid = true;
        if (this.step === 2 && this.data.allowPartnerSeeInformation) {
          return this.$modal.show({
            text: `Congratulations, you are now linked to ${this.partner.name}. Your partner will be able to view your information.`,
            buttons: [
              {
                text: 'Ok',
                color: 'primary white--text',
                onAction: () => {
                  this.loading = true;
                  api.PartnerConnect.consentFormConfirmation(
                    this.partner.patientId,
                    '2222-02-02',
                    1
                  )
                    .then(async (res) => {
                      let body = {
                        pid: this.user.patientId,
                        note:
                          'Patient(' +
                          this.user.fullName +
                          ') Added a Partner(' +
                          this.partner.name +
                          ') on Prelude Connect',
                        userId: this.user.userId
                      };
                      await api.PartnerConnect.notes(body);

                      this.$router.push('/my-partner');
                    })
                    .catch(() => {
                      this.$store.commit('showSnackbar', {
                        message: 'Consent confirmation unavailable',
                        color: 'red'
                      });
                    })
                    .finally(() => (this.loading = false));
                }
              }
            ]
          });
        }

        if (this.step === 1 && !this.data.allowPartnerSeeInformation) {
          return this.$modal.show({
            text: `Congratulations, you are now linked to ${this.partner.name}. Your partner will be able to view your information.`,
            buttons: [
              {
                text: 'Ok',
                color: 'primary white--text',
                onAction: () => {
                  this.loading = true;
                  api.PartnerConnect.consentFormConfirmation(
                    this.partner.patientId,
                    null,
                    null
                  )
                    .then(async (res) => {
                      let body = {
                        pid: this.user.patientId,
                        note:
                          'Patient(' +
                          this.user.fullName +
                          ') Added a Partner(' +
                          this.partner.name +
                          ') on Prelude Connect',
                        userId: this.user.userId
                      };
                      await api.PartnerConnect.notes(body);
                      this.$router.push('/my-partner');
                    })
                    .catch(() => {
                      this.$store.commit('showSnackbar', {
                        message: 'Invitation to join unavailable',
                        color: 'red'
                      });
                    })
                    .finally(() => (this.loading = false));
                }
              }
            ]
          });
        } else {
          return this.step++;
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    checkbox(check) {
      this.data[check] = !this.data[check];
    },
    showDate(date) {
      date = date.split('T')[0];
      date = date.split('-');
      var month = date[1];
      var day = date[2];
      var year = date[0];

      return `${month}/${day}/${year}`; //`${year}-${month}-${day}`;
    }
  },
  mounted() {
    this.loadConsents();
    this.partner = this.getPartner;
  }
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
.align {
  margin-left: 15px;
}
</style>
